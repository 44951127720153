import React, { useEffect, useRef, useState } from 'react';
import './Dashboard.css';
import DashboardBoxSection from "../../components/dashboard-box-section/DashboardBoxSection";
import { createTheme } from '@mui/material';
import DashboardEmployeeTable from '../../components/dashboard-employee-table/DashboardEmployeeTable';
import DashboardLabelBlock from '../../components/dashboard-label-block/DashboardLabelBlock';
import DashboardDatePicker from '../../components/dashboard-datepicker/DashboardDatePicker';
import { useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import { add } from 'date-fns';
import CrmUtils from '../../services/crm-utils/CrmUtils';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRange } from '../../services/store/GenericReducer';

export default function Dashboard(props) {
  const store = useSelector(state => state.reducer)
  const dispatch = useDispatch()
  const theme = useTheme()
  const [locale] = useState('heIL')
  const [filtersInfo, setFiltersInfo] = useState(false)
  const [initSummaryData, setInitSummaryData] = useState(false)
  const [dashboardManagerInfo, setDashboardManagerInfo] = useState(false)
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme],
  )
  const leadingColor = props.partner.partnerConfig.leadingColor;
  const [selectedDateRange, setSelectedDateRange] = useState([ //get the store date range from redux at page load
    {
        startDate: add(Date.now(), {days: 0}),
        endDate: add(Date.now(), {days: 1}),
        key: 'selection'
    }
  ])
  const prevSelectedDateRange = useRef(selectedDateRange)


  const getDataByDate = async (selectedDateRange) => {
    try{
      dispatch(setDateRange(JSON.stringify(selectedDateRange)))
      let dateFrom = selectedDateRange[0].startDate
      let dateTo = selectedDateRange[0].endDate
      let res = await CrmUtils.getDashboardInitSummary(dateFrom, dateTo)
      setInitSummaryData(res.data)
    }catch(e){console.error(e)}
  }

  useEffect(() => {
    getDataByDate(selectedDateRange)
    // console.log(store)
    // store?.dateRange && getDataByDate(selectedDateRange)
    //dispatch(setDateRange(selectedDateRange))
  }, [selectedDateRange])

  // useEffect(() => {
  //   if(store?.dateRange){
  //     let date = JSON.parse(store.dateRange)
  //     console.log(date)
  //   } //
  // }, [store?.dateRange])

  useEffect(() => {
    getAllManagerInfo()
    getDashboardInfoFilters()
  }, [])

  const getDashboardInfoFilters = async () => {
    try{
      let res = await CrmUtils.getDashboardFiltersInfo()
      setFiltersInfo(res.data)
    }catch(e){console.error(e)}
}

  const getAllManagerInfo = async () => {
    try{
      let res = await CrmUtils.getDashboardManagerInfo()
      setDashboardManagerInfo(res.data)
    }catch(e){console.error(e)}
  }

  return (
    <div id="main" role="main" className='main-dashboard'>
      <div id="first-content-section">
        <div className="content-container">
          <h1>לוח בקרה</h1>
          <div className='section'>
            <div className='section-datePicker'>
              <DashboardDatePicker key='dashboard.datePicker' active={true} theme={themeWithLocale} selectedDateRange={selectedDateRange} setSelectedDateRange={setSelectedDateRange} />
            </div>
            <DashboardBoxSection 
              filtersInfo={filtersInfo}
              partner={props.partner} 
              leadingColor={leadingColor} 
              themeWithLocale={themeWithLocale} 
              selectedDateRange={selectedDateRange} 
              initSummaryData={initSummaryData} 
              setSelectedDateRange={setSelectedDateRange} 
              prevSelectedDateRange={prevSelectedDateRange}/>
          </div>
          <div className='section-volunteer'>
            <h1>ההתנדבויות של העובדים שלי</h1>
          </div>
          <div className='section-table'>
            <DashboardLabelBlock leadingColor={leadingColor} managerInfo={dashboardManagerInfo}/>
          </div>
          <DashboardEmployeeTable margin={1} managerInfo={dashboardManagerInfo} />
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import AccountProgressBlock from '../account-progress-block/AccountProgressBlock';
import './AccountVolunteeringTable.css';
import { DataGrid, heIL } from '@mui/x-data-grid';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import AccordionBlock from '../accordion-block-vol/AccordionBlock';
import { BrowserView, MobileView } from 'react-device-detect';
import Utils from '../../services/utils/Utils';
import CrmUtils from '../../services/crm-utils/CrmUtils';


export default function AccountVolunteeringTable(props) {

    const [locale, setLocale] = React.useState('heIL');
    const [volunteerMatchProjects, setVolunteerMatchProjects] = useState([])
    const [statusArray, setStatusArray] = useState([])
    const [matchCount, setMatchCount] = useState(0)
    const [statusUpdate, setStatusUpdate] = useState(0)
    const additionalInfo = JSON.parse(props.partner.partnerConfig.additionalJsonConfig)
    const theme = useTheme();
    theme.typography = {
        font: "14px 'AlmoniDL',sans-serif"
    };
    const themeWithLocale = React.useMemo(
        () => createTheme(theme, locales[locale]),
        [locale, theme],
    );

    const columns = [
        { 
            field: 'projectTitle', 
            headerName: 'שם הפרויקט', 
            headerClassName: 'info-label',
            width: 400, 
            overflow: 'hidden'
        },
        { 
            field: 'dateProjectText', 
            headerName: 'תאריך', 
            width: 250, 
            headerClassName: 'info-label' 
        },
        { 
            field: 'matchValue', 
            headerName: 'מס׳ שעות מזכות', 
            width: 120, 
            headerClassName: 'info-label' 
        },
        {
            field: 'matchCount',
            headerName: 'שעות בפועל',
            width: 100, 
            headerClassName: 'info-label',
        },
        {
            field: 'bubbleMatchStatusID',
            headerName: 'סטטוס',
            width: 130,
            headerClassName: 'info-label',
            renderCell: (params) => {
                return <div className="MuiDataGrid-cellContent">{statusArray[params.row.bubbleMatchStatusID]}</div>
            }
        },
        {
            field: 'linkTo',
            headerName: '',
            width: 100,
            headerClassName: 'info-label',
            sortable: false,
            renderCell: (params) => {
                return (params.row.isActive && params.row.bubbleMatchStatusID !== 3) ? <button className="cancel-event-btn" onClick={ () => cancelRegistration(params.row.id)}>ביטול הרשמה</button> : "";
            }
        }
    ];

    const getVolunteeringMatchList = async () => {  
        try{
            let finalArray = await CrmUtils.getVolunteerMatchList() 
            setVolunteerMatchProjects(finalArray);
            let count = 0;
            finalArray.map((item) => {
                count = count + item.matchCount;
            })
            setMatchCount(count)
        } catch(err){
            console.log(err)
        }
	}

    const getVolunteeringStatus = async () => {  
        try{
            let statuses = await CrmUtils.getVolunteeringStatus(props.partner.partnerID) 
            let status_dictionary = []; 
            statuses.data.map((item) => {
                status_dictionary[item.bubbleMatchStatusID] = item.title
            })
            setStatusArray(status_dictionary);
        } catch(err){
            console.log(err)
        }
	}

    const cancelRegistration = async (row_id) => {
        console.log('Delete_row_' + row_id);
        let res = await CrmUtils.updateVolunteeringStatus(row_id)
        console.log(res)
        setStatusUpdate(prevCount => prevCount + 1)
    }

    const init = () => {
        getVolunteeringMatchList()
        getVolunteeringStatus()
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
       init()
    }, [statusUpdate])

  return (
    <>

        <AccountProgressBlock 
            leading_color={props.leading_color}
            progress_text={"בוצעו " + matchCount + '/' + additionalInfo.TotalRequiredMatchHours +" שעות חונכות" }
            dataProgressValue={matchCount} 
            dataMaxValue={additionalInfo.TotalRequiredMatchHours}
        />

        <BrowserView>
            <ThemeProvider theme={themeWithLocale}>
                <DataGrid
                    autoHeight {...volunteerMatchProjects}
                    sx={{ backgroundColor: '#fff', marginBottom: '20px'}}
                    rows={volunteerMatchProjects} //rows
                    columns={columns}
                    pageSize={volunteerMatchProjects.length}
                    rowsPerPageOptions={[volunteerMatchProjects.length]}
                    localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
                    disableSelectionOnClick
                    getCellClassName={(params) => {
                        return (params.row.isActive === 'true' && params.field === 'isActive' ) ? "green-status" : '';
                    }}
                />
            </ThemeProvider>
        </BrowserView>
        
        <MobileView>
        {     
            volunteerMatchProjects?.map(function(item, i){
                return <AccordionBlock cancelRegistration={cancelRegistration} panel_num={"panel" + i} key={i} data={item}/>
            })
        }
        </MobileView>

   </>
  )
}

import React, { useEffect, useState } from 'react';
import { DataGrid, heIL } from '@mui/x-data-grid';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import {BrowserView, MobileView} from 'react-device-detect';
import AccordionBlockDashboard from '../accordion-block-dashboard/AccordionBlockDashboard';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

export default function DashboardEmployeeTable(props){

    const [rows, setRows] = useState(false);
    const [locale] = React.useState('heIL');
    const theme = useTheme();
    theme.typography = {
        font: "14px 'AlmoniDL'"
    };
    const themeWithLocale = React.useMemo(
        () => createTheme(theme, locales[locale]),
        [locale, theme],
    );

    const arrangeTableData = () => {
        let tempRows = []
        props.managerInfo.volunteersTable?.map((item, index) => {
            let row = {
                id: index + 1,
                employeeName: item.volunteerName,
                employeeId: item.volunteerExternalID,
                projectName: item.projectTitle,
                date: item.dateProjectText,
                numOfHoursPoints: item.matchHours,
                actualHours: item.matchCount,
                status: item.bubbleMatchStatusID,
                dateRegister: item.dateCreatedText
            }
            tempRows.push(row)
        })
        setRows(tempRows);
    }

    useEffect(() => {
        props.managerInfo.volunteersTable && arrangeTableData()
    }, [props.managerInfo.volunteersTable])

    const columns = [
        {
            field: 'employeeName',
            headerName: 'שם עובד',
            flex: 0.25,
            headerClassName: 'info-label',
            sortable: true
        },
        { 
            field: 'employeeId', 
            headerName: 'מזהה עובד', 
            flex: 0.13, 
            headerClassName: 'info-label' 
        },
        { 
            field: 'projectName', 
            headerName: 'שם הפרויקט', 
            headerClassName: 'info-label',
            flex: 0.3, 
            overflow: 'hidden'
        },
        {
            field: 'date',
            headerName: 'תאריך',
            flex: 0.2, 
            headerClassName: 'info-label',
        },
        {
            field: 'numOfHoursPoints',
            headerName: 'מס׳ שעות מזכות',
            flex: 0.1,
            headerClassName: 'info-label',
            
        },
        { 
            field: 'actualHours', 
            headerName: 'שעות בפועל', 
            headerClassName: 'info-label',
            flex: 0.1,       
        },
        { 
            field: 'status', 
            headerName: 'סטטוס',
            cellClassName: 'info-label',
            flex: 0.12,       
        },
        { 
            field: 'dateRegister', 
            headerName: 'תאריך הרשמה', 
            headerClassName: 'info-label',
            flex: 0.15,       
        }
    ];
    
    return (
        <>
            {rows && <BrowserView>
                <ThemeProvider theme={themeWithLocale}>
                    <DataGrid
                        autoHeight {...rows}
                        sx={{
                            backgroundColor: '#fff', marginBottom: '20px', border: 'unset',
                            '& .MuiDataGrid-columnSeparator': {
                                display: 'none'
                            },
                            '& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderDraggableContainer': {
                                '& .MuiDataGrid-menuIcon': {
                                    display: 'none'
                                },
                                '& .MuiDataGrid-iconButtonContainer': {
                                    visibility: 'visible',
                                    width: '0 !important'
                                  }
                            },
                            '& .MuiTablePagination-actions': {
                                display: 'none'
                            },
                            '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus' : {
                                outline: 'unset',
                            }
                        }}
                        rows={rows}
                        columns={columns}
                        getCellClassName={(params) => {
                            if (params.field === 'status' && params.value === 'בוצע') {
                              return 'green-status';
                            }
                            return 'info-label';
                          }}
                        pageSize={rows.length}
                        rowsPerPageOptions={[rows.length]}
                        localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
                        // components={{
                        //     ColumnUnsortedIcon: () => <UnfoldMoreIcon />,
                        //     ColumnSortedAscendingIcon: () => <i className='fa-angle-up'></i>,
                        //     ColumnSortedDescendingIcon: () => <i className='fa-angle-down'></i>
                            
                        //   }}
                        disableSelectionOnClick
                    />
                </ThemeProvider>
            </BrowserView>}

            <MobileView>
                {
                    rows && rows?.map(function (item, i) {
                        return <AccordionBlockDashboard key={i} data={item} />
                    })
                }
            </MobileView>
        
        </>
    )

}
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './FilterLocation.css'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'


export default function FilterLocation(props) {

    const store = useSelector(state => state.reducer)
    const [showDropDown, setShowDropDown] = useState(false)
    const [cityDropDown, setCityDropDown] = useState([])
    const [filterValue, setFilterValue] = useState(false)
    const [filterDisplay, setFilterDisplay] = useState(props.apiFilter)

    useEffect(() => {
        filterValue && props.apiFilter && setFilterDisplay(filterValue)
        props.apiFilter.filterTypeID && props.updateFilter(props.apiFilter.filterTypeID, filterValue)
    }, [filterValue])

    const setDropdown = () => {
        let innerDropDown = []     
        props.filter && props.filter.length > 0 && props.filter?.map(city => {
            let obj = {}
            obj.id = city.cityID
            obj.label = city.cityTitle
            innerDropDown.push(obj)
        })
        setCityDropDown(innerDropDown)
    }

    const handleFilterClick = (e) => {
        setShowDropDown(!(e.target.id != "city-search" && showDropDown))
    }

    useEffect(() => {
        setDropdown()
        store.filterChoices[props.apiFilter.filterTypeID] && setFilterValue(store.filterChoices[props.apiFilter.filterTypeID].choice)
    }, [])

    useEffect(() => {
        props.clearFilterLabel > 0 && setFilterDisplay(props.apiFilter)
        props.clearFilterLabel > 0 && setFilterValue(false)
    }, [props.clearFilterLabel])

  return (
    <div onClick={(e) => {handleFilterClick(e)}} aria-expanded={showDropDown} className={`field-wrapper filter_location dropdown cities-dropdown ${showDropDown ? 'show' : ''}`}> <label htmlFor="location-search" className="sr-only">איפה תרצו להתנדב?</label>
        <div className="input-with-icon"> 
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#298CCA" width="18" height="24" viewBox="0 0 18 24">
                <defs>
                    <path id="a" d="M0 0h17.957v24H0z" />
                </defs>
                <g fillRule="evenodd">
                    <mask id="b" fill="#fff">
                        <use xlinkHref="#a" />
                    </mask>
                    <path d="M15.066 13.815l-6.087 7.906-6.086-7.906A7.76 7.76 0 011.38 9.184 7.872 7.872 0 014.5 2.91c1.97-1.48 4.49-1.89 6.914-1.126 2.244.708 4.078 2.594 4.786 4.92.76 2.504.347 5.095-1.135 7.111M17.52 6.29c-.84-2.764-3.023-5.005-5.696-5.848C8.977-.457 6.009.03 3.68 1.778A9.254 9.254 0 001.004 4.97 9.353 9.353 0 000 9.184c0 1.994.62 3.89 1.792 5.48L8.979 24l7.179-9.323.009-.012c1.754-2.382 2.247-5.435 1.353-8.376" mask="url(#b)" />
                    <path d="M9 12.571A3.574 3.574 0 015.429 9 3.574 3.574 0 019 5.429 3.574 3.574 0 0112.571 9 3.574 3.574 0 019 12.571M9 4C6.243 4 4 6.243 4 9s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5" />
                </g>
            </svg> 
            <input type="text" id="location-search" className={filterValue ? "text-blue" : ""} placeholder={filterDisplay.cityTitle || filterDisplay.title || filterDisplay.label} /> 
            <span className="dropdown-arrow"></span> 
        </div>
        <div className="dropdown-content">
            <div className="search-input-wrapper"> 
                <Autocomplete
                    freeSolo
                    disableClearable                      
                    sx={{
                        display: 'inline-block',
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          padding: '0px',
                            borderRadius: '0px',
                        },
                        '& label': {
                            display: 'none'
                        },
                        '& fieldset': {
                            display: 'none'
                        },
                        '& input': {
                            height: '21px !important',
                            padding: '7px 12px !important',
                            font: "16px 'AlmoniDL',Arial,sans-serif"
                        },
                        '& ul': {
                            font: "14px 'AlmoniDL',Arial,sans-serif !important"
                        },
                      }}
                    id="city-search"
                    PaperComponent={({ children }) => (
                        <Paper style={{font: "14px 'AlmoniDL',Arial,sans-serif", fontWeight: '500'}}>{children}</Paper>
                    )}
                    className="city-search"
                    onChange={(e, value) => {
                        let index = props.filter.findIndex(x => x.cityID === value.id)
                        index !== -1 && setFilterValue(props.filter[index])
                    }}
                    options={!cityDropDown ? [{label:"Loading...", id:0}] : cityDropDown}
                    renderInput={(params) => <TextField {...params} placeholder="הקלידו שם ישוב" />}
                />
            </div>
            <div className="location-list-container list-container">
                <ul className="location-list">
                    <li onClick={() => {setFilterDisplay(props.apiFilter); setFilterValue(false)}}> X נקה בחירה</li>
                    {
                        props.filter?.map((item, index) => {
                            return <li key={index} onClick={() => setFilterValue(item)} data-param-name="filterFieldDisplay"> <a>{item.cityTitle}</a> </li>
                        })
                    }
                </ul>
            </div>
        </div>
    </div>
    
  )
}
